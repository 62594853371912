import * as client_hooks from '../../../hooks/client.js';
import * as universal_hooks from '../../../hooks/universal.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [3];

export const dictionary = {
		"/": [7],
		"/padarmariann": [8,[2]],
		"/process": [9],
		"/protected/admin": [10,[3,4]],
		"/protected/admin/audience/icps": [11,[3,4]],
		"/protected/admin/audience/icps/[icp_id]": [12,[3,4]],
		"/protected/admin/audience/icps/[icp_id]/persona/[persona_id]": [13,[3,4]],
		"/protected/admin/landing/editor/page/[page_id]": [14,[3,4]],
		"/protected/admin/landing/list": [15,[3,4]],
		"/protected/landing/view/[page_id]": [16,[3]],
		"/public/kolab365.com": [17],
		"/public/landing/[topic]/[variant]": [~18],
		"/public/marketing.kolab365.com/landing1": [19],
		"/public/munkakor/kategoriak/[category]": [20],
		"/public/munkakor/kategoriak/[category]/content": [21],
		"/public/toborzas.kolab365.com": [22],
		"/public/toborzas.kolab365.com/ai_a_toborzasban": [23],
		"/public/toborzas.kolab365.com/ai_a_toborzasban/ighuomah5taCoeniehaPhufietoh9g/ceingohtaeco8OotieshaeR2sik6ae": [24],
		"/public/toborzas.kolab365.com/ai_a_toborzasban/success": [25],
		"/public/toborzas.kolab365.com/allashirdetes": [26],
		"/public/toborzas.kolab365.com/allashirdetes/gaecei7ohPo3ou7ahphek9gejahpoo/woo2eeFe7ios9aevuukai8aecaicu7": [27],
		"/public/toborzas.kolab365.com/allashirdetes/siker": [28],
		"/public/toborzas.kolab365.com/start": [29],
		"/public/user/login": [30,[5]],
		"/public/webovi.hu": [31,[6]],
		"/sentry-example": [32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';