import * as Sentry from '@sentry/sveltekit';

if (import.meta.env.MODE === 'production') {
Sentry.init({
    dsn: 'https://d8e6385ce96ed7402c62f2acc51ba9b3@o608706.ingest.us.sentry.io/4507931997896704',
    tracesSampleRate: 1.0,

    /*integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: true,
      }),
    ],*/
  });

}
