const LANDING_REGEX = /^\/landing\/(?<TOPIC>[a-z0-9]{1,100})\/(?<VARIANT>[a-z0-9]{1,100})/;


export function reroute ({ url }) {
	if ((import.meta.env.MODE === 'production') && (url.hostname === "kolab365.com")) {
		return `/public/kolab365.com${url.pathname}`;
	}

	if ((import.meta.env.MODE === 'production') && (url.hostname === "toborzas.kolab365.com")) {
		return `/public/toborzas.kolab365.com${url.pathname}`;
	}

	if (url.pathname.indexOf('/app/') === 0) {
		return url.pathname;
	}

	if (url.pathname.match(LANDING_REGEX)) {
		return `/public${url.pathname}`;
	}

	return url.pathname;
};
